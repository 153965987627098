[class$='-inner'],
.gallery-thumbnails-wrapper {
    max-width: $inner-max-width;
    margin: auto;
    padding: 0 40px;
}

.scroll-block {
    opacity: 0;
    z-index: 0;

    @include respond-to(screen-md) {
        height: $nav-height;
    }

    // &.sticky {
    //     height: $nav-height-sticky*2;

    //     @include respond-to(screen-sm) {
    //         height: $nav-height-sticky;
    //     }
    // }
}

.page {
    padding-top: 30px;
    padding-bottom: 30px;

    @include respond-to(screen-sm) {
        height: 100vh;
        padding-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: $font-stack-common;
        font-weight: 700;
    }

    h1 {
        font-size: 3rem;
        line-height: 3rem;
    }

    h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 2rem;
    }

    h4 {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }

    h5 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    h6 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    li {
        font-family: $font-stack-header;
        font-size: 1.4rem;
        font-weight: 400;
    }

    p {
        font-family: $font-stack-header;
        font-size: 1.4rem;
        margin-bottom: 0;
    }

    &.active {
        .page-inner:after,
        .gallery-thumbnails-wrapper:after {
            @include respond-to(screen-sm) {
                // background-color: orange;
                // box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 10%);
            }
        }
    }

}

.button-treatment {
    background-color: transparent;

    &:after  {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background: #000;
        transform-origin: center center;
        transform: scaleX(0);
        transition: $nav-animation-spd*1.5;
    }

    &:hover{
        &:after {
            transform: scaleX(0.7);
            opacity: 0.3;
        }

        &:not(.active) {
            &:after {
                transform: scaleX(1) scaleY(1);
            }
        }
    }

    &.active {
        &:after {
            transform: scaleX(0.9);
        }
    }

    &:not(.active) {
        &:after {}
    }

    a, button {
        padding: 10px 10px 5px;
        text-decoration: none;
        border: 0;
        background: none;
        font-family: $font-stack-common;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1.5rem;
        cursor: pointer;
        color: #54032B;
        transition: 0.35s;
    }
}

.page-inner:not(.gallery-page-inner) {
    position: relative;

    @include respond-to(screen-sm) {
        top: 50%;
        transform: translateY(-50%);
    }
}

.page-inner {
    position: relative;
}

.page-inner:after,
.gallery-thumbnails-wrapper:after {
    content: "";
    width: 100%;
    height: 130%;
    background-color: none;
    position: absolute;
    top: auto;
    left: 50%;
    z-index: -1;
    transform-origin: 0 0;
    transform: rotate(1deg) translateX(-50%) translatey(0);
    box-shadow: 0;
    transition: 1s;

    @include respond-to(screen-sm) {
        top: 50%;
        transform: rotate(1deg) translateX(-50%) translatey(-50%);
    }
}

.page-inner:hover:after,
.gallery-thumbnails-wrapper:hover:after {}
