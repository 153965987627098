@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;700&display=swap');

$break-small: 561px !default;
$break-medium: 769px !default;
$break-large: 1041px !default;
$break-extralarge: 1441px !default;
$product-page-width: 1440px !default;

$inner-max-width: 1000px;

$nav-height: 68px;
$nav-height-sticky: 50px;

$font-stack-header: 'Josefin Slab', serif;
$font-stack-common: 'Josefin Sans', sans-serif;

$nav-animation-spd: 0.25s;

@mixin respond-to($media) {
    @if $media == screen-iphone5 {
        @media only screen and (max-width: 440px) { @content; }
    }
    @if $media == screen-sm {
        @media only screen and (min-width: $break-small) { @content; }
    }
    @else if $media == screen-md {
        @media only screen and (min-width: $break-medium) { @content; }
    }
    @else if $media == screen-lg {
        @media only screen and (min-width: $break-large) { @content; }
    }
    @else if $media == screen-xl {
        @media only screen and (min-width: $break-extralarge) { @content; }
    }
    @else if $media == hidpi {
        @media
            only screen and (-webkit-min-device-pixel-ratio: 1.3),
            only screen and (-o-min-device-pixel-ratio: 13/10),
            only screen and (min-resolution: 120dpi) { @content; }
    }
}

html, body {
    font-size: 18px;
}

.mhc {
    overflow-y: scroll;

    * {
        color: #000;
    }

    a {
        font-family: $font-stack-common;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    .hidden {
        display: none;
    }
}

@import './_partials/common';
@import './_partials/navigation';
@import './_partials/home';
@import './_partials/about';
@import './_partials/gallery';
@import './_partials/contact';
