.about {
    background-color: #f8f8f8;

    &.active {
        .page-inner:after {}
    }

    .about-text {
        margin-bottom: 20px;

        ul {
            padding-left: 20px;
        }
    }
}