.navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: $nav-height + 6px;
    border-bottom: 6px solid #000;
    // background-color: #B3AABD;
    background-color: #fff;
    z-index: 999;
    transition: $nav-animation-spd;

    @include respond-to(screen-md) {
        height: $nav-height;
    }

    &.sticky {
        @include respond-to(screen-md) {
            // background-color: rgba(179, 170, 189, 0.8);
            background-color: rgba(255, 255, 255, 0.8);
            border-bottom: 2px solid #000;
        }
    }

    // &.sticky {
    //     height: $nav-height;

    //     @include respond-to(screen-sm) {
    //         height: $nav-height-sticky;
    //         border-bottom: 3px solid #000;
    //     }

    //     .menu ul li {
    //         &:after {
    //             height: 2px;
    //         }
    //     }

    //     img.app-logo {
    //         width: 50px;
    //     }
    // }

    .navigation-inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition: $nav-animation-spd;
    }

    .hamburger-nav {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        right: 0;
        transform: translateX(calc(100% - 65px));
        // background-color: #B3AABD;
        transition: $nav-animation-spd*1.2;

        @include respond-to(screen-md) {
            display: none;
        }

          &.menu-open {
            width: 100%;
            transform: translateX(calc(0%));
            flex-direction: column;
            background-color: rgba(255, 255, 255, 0.75);

            .hamburger-icon {
                margin-right: 0;
                margin-top: 5px;
                transform: scale(0.8);
            }

            .hamburger-icon > div:first-child {
                transform: rotate(45deg) translateY(10px) translateX(10px);
            }

            .hamburger-icon > div:nth-child(2) {
                opacity: 0;
            }

            .hamburger-icon > div:last-child {
                transform: rotate(-45deg) translateY(-10px) translateX(10px);
            }

            .menu ul {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .hamburger-icon {
            margin-right: 35px;
            cursor: pointer;
            transition: $nav-animation-spd;

            @include respond-to(screen-iphone5) {
                margin-right: 10px;
            }

            & > div {
                width: 50px;
                height: 6px;
                margin-bottom: 8px;
                background-color: purple;
                transition: 0.5s;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .menu {
        cursor: pointer;

        &.desktop {
            display: none;

            @include respond-to(screen-md) {
                display: block;
            }
        }

        .menu-inner {
            display: flex;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;

            @include respond-to(screen-sm) {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .menu-inner-item {
                position: relative;
                margin-right: 0;
                padding: 0;
                list-style: none;
                background-color: transparent;

                @include respond-to(screen-sm) {
                    margin-right: 10px;
                }



                a, button {}
            }
        }
    }

    img.app-logo {
        width: 70px;
        transition: $nav-animation-spd;
        margin-left: 15px;
        cursor: pointer;

        @include respond-to(screen-md) {
            width: 80px;
        }
    }
}