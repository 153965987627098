#home {

    margin-top: $nav-height + 50px;

    @include respond-to(screen-sm) {
        margin-top: 0;
    }

    .home-text {
        margin-bottom: 20px;

        ul {
            padding-left: 20px;
        }
    }

    &.active {
        .page-inner:after {}
    }
}