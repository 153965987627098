.contact {
    .contact-form {
        margin-top: 40px;

        label {
            font-family: $font-stack-common;
        }

        input, textarea {
            margin-bottom: 20px;
            font-family: $font-stack-header;
            border: 0;
            background: none;
            border-bottom: 2px solid #000;

            &:focus {
                outline: none;
            }
        }

        textarea {
            width: calc(100% - 20px);
            max-width: calc(100% - 20px);
            min-width: calc(100% - 20px);
            height: 100px;
            max-height: 100px;
            min-height: 100px;

            @include respond-to(screen-sm) {
                min-width: 400px;
                max-width: 400px;
                width: 400px;
            }
        }

        .input-label-wrapper {
            padding-left: 10px;
        }

        .contact-form-inner {
            padding-left: 0;
            padding-right: 0;

            & > h2 {
                margin-bottom: 20px;
            }
        }

        .submit-wrapper {
            position: relative;
            width: 169px;

            button.submit-btn {
                border: 0;
                background: none;

                &:focus-visible {
                    // background-color: transparent;
                    outline: 0;
                }
            }
        }

    }
}