@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;700&display=swap");
html, body {
  font-size: 18px;
}

.mhc {
  overflow-y: scroll;
}
.mhc * {
  color: #000;
}
.mhc a {
  font-family: "Josefin Sans", sans-serif;
}
.mhc h1, .mhc h2, .mhc h3, .mhc h4, .mhc h5, .mhc h6 {
  margin: 0;
}
.mhc .hidden {
  display: none;
}

[class$=-inner],
.gallery-thumbnails-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 0 40px;
}

.scroll-block {
  opacity: 0;
  z-index: 0;
}
@media only screen and (min-width: 769px) {
  .scroll-block {
    height: 68px;
  }
}

.page {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 561px) {
  .page {
    height: 100vh;
    padding-top: 0;
  }
}
.page h1, .page h2, .page h3, .page h4, .page h5, .page h6 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}
.page h1 {
  font-size: 3rem;
  line-height: 3rem;
}
.page h2 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}
.page h3 {
  font-size: 2rem;
  line-height: 2rem;
}
.page h4 {
  font-size: 1.75rem;
  line-height: 1.75rem;
}
.page h5 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.page h6 {
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.page li {
  font-family: "Josefin Slab", serif;
  font-size: 1.4rem;
  font-weight: 400;
}
.page p {
  font-family: "Josefin Slab", serif;
  font-size: 1.4rem;
  margin-bottom: 0;
}
.button-treatment {
  background-color: transparent;
}
.button-treatment:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #000;
  transform-origin: center center;
  transform: scaleX(0);
  transition: 0.375s;
}
.button-treatment:hover:after {
  transform: scaleX(0.7);
  opacity: 0.3;
}
.button-treatment:hover:not(.active):after {
  transform: scaleX(1) scaleY(1);
}
.button-treatment.active:after {
  transform: scaleX(0.9);
}
.button-treatment a, .button-treatment button {
  padding: 10px 10px 5px;
  text-decoration: none;
  border: 0;
  background: none;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.5rem;
  cursor: pointer;
  color: #54032B;
  transition: 0.35s;
}

.page-inner:not(.gallery-page-inner) {
  position: relative;
}
@media only screen and (min-width: 561px) {
  .page-inner:not(.gallery-page-inner) {
    top: 50%;
    transform: translateY(-50%);
  }
}

.page-inner {
  position: relative;
}

.page-inner:after,
.gallery-thumbnails-wrapper:after {
  content: "";
  width: 100%;
  height: 130%;
  background-color: none;
  position: absolute;
  top: auto;
  left: 50%;
  z-index: -1;
  transform-origin: 0 0;
  transform: rotate(1deg) translateX(-50%) translatey(0);
  box-shadow: 0;
  transition: 1s;
}
@media only screen and (min-width: 561px) {
  .page-inner:after,
.gallery-thumbnails-wrapper:after {
    top: 50%;
    transform: rotate(1deg) translateX(-50%) translatey(-50%);
  }
}

.navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  width: 100%;
  height: 74px;
  border-bottom: 6px solid #000;
  background-color: #fff;
  z-index: 999;
  transition: 0.25s;
}
@media only screen and (min-width: 769px) {
  .navigation {
    height: 68px;
  }
}
@media only screen and (min-width: 769px) {
  .navigation.sticky {
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 2px solid #000;
  }
}
.navigation .navigation-inner {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: 0.25s;
}
.navigation .hamburger-nav {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  transform: translateX(calc(100% - 65px));
  transition: 0.3s;
}
@media only screen and (min-width: 769px) {
  .navigation .hamburger-nav {
    display: none;
  }
}
.navigation .hamburger-nav.menu-open {
  width: 100%;
  transform: translateX(calc(0%));
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);
}
.navigation .hamburger-nav.menu-open .hamburger-icon {
  margin-right: 0;
  margin-top: 5px;
  transform: scale(0.8);
}
.navigation .hamburger-nav.menu-open .hamburger-icon > div:first-child {
  transform: rotate(45deg) translateY(10px) translateX(10px);
}
.navigation .hamburger-nav.menu-open .hamburger-icon > div:nth-child(2) {
  opacity: 0;
}
.navigation .hamburger-nav.menu-open .hamburger-icon > div:last-child {
  transform: rotate(-45deg) translateY(-10px) translateX(10px);
}
.navigation .hamburger-nav.menu-open .menu ul {
  margin-top: 0;
  margin-bottom: 0;
}
.navigation .hamburger-nav .hamburger-icon {
  margin-right: 35px;
  cursor: pointer;
  transition: 0.25s;
}
@media only screen and (max-width: 440px) {
  .navigation .hamburger-nav .hamburger-icon {
    margin-right: 10px;
  }
}
.navigation .hamburger-nav .hamburger-icon > div {
  width: 50px;
  height: 6px;
  margin-bottom: 8px;
  background-color: purple;
  transition: 0.5s;
}
.navigation .hamburger-nav .hamburger-icon > div:last-child {
  margin-bottom: 0;
}
.navigation .menu {
  cursor: pointer;
}
.navigation .menu.desktop {
  display: none;
}
@media only screen and (min-width: 769px) {
  .navigation .menu.desktop {
    display: block;
  }
}
.navigation .menu .menu-inner {
  display: flex;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 561px) {
  .navigation .menu .menu-inner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.navigation .menu .menu-inner .menu-inner-item {
  position: relative;
  margin-right: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
@media only screen and (min-width: 561px) {
  .navigation .menu .menu-inner .menu-inner-item {
    margin-right: 10px;
  }
}
.navigation img.app-logo {
  width: 70px;
  transition: 0.25s;
  margin-left: 15px;
  cursor: pointer;
}
@media only screen and (min-width: 769px) {
  .navigation img.app-logo {
    width: 80px;
  }
}

#home {
  margin-top: 118px;
}
@media only screen and (min-width: 561px) {
  #home {
    margin-top: 0;
  }
}
#home .home-text {
  margin-bottom: 20px;
}
#home .home-text ul {
  padding-left: 20px;
}
.about {
  background-color: #f8f8f8;
}
.about .about-text {
  margin-bottom: 20px;
}
.about .about-text ul {
  padding-left: 20px;
}

.gallery {
  padding-top: 80px;
  padding-bottom: 40px;
  background-color: #f8f8f8;
}
.gallery .gallery-thumbnails-wrapper {
  padding-top: 40px;
  position: relative;
}
@media only screen and (min-width: 561px) {
  .gallery .gallery-thumbnails-wrapper {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (min-width: 769px) {
  .gallery .gallery-thumbnails-wrapper {
    max-width: 690px;
  }
}
.gallery .gallery-thumbnails-wrapper:after {
  transform: rotate(1deg) translateX(-50%) translatey(-45%);
}
.gallery .gallery-thumbnails {
  margin-top: 19px;
}
.gallery .gallery-thumbnails button,
.gallery .gallery-thumbnails a {
  cursor: pointer;
}
.gallery .gallery-thumbnails-thumb {
  margin-right: 20px;
  margin-bottom: 20px;
}
.gallery .gallery-thumbnails-thumb img {
  width: 100%;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.gallery .gallery-work.lightboxed {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}
.gallery .gallery-work .close-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  cursor: pointer;
}
.gallery .gallery-work .close-wrapper img {
  width: 100%;
}
.gallery .gallery-work .gallery-work-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
  padding: 50px 10px 20px;
  background-color: #fff;
  overflow-y: scroll;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 561px) {
  .gallery .gallery-work .gallery-work-inner {
    min-width: 500px;
    min-height: 556px;
    height: auto;
    max-height: 80%;
  }
}
@media only screen and (max-height: 610px) {
  .gallery .gallery-work .gallery-work-inner {
    height: 80%;
    min-height: 80%;
  }
}
@media only screen and (min-width: 769px) {
  .gallery .gallery-work .gallery-work-inner {
    min-width: 768px;
    width: auto;
  }
}
@media only screen and (min-width: 1041px) {
  .gallery .gallery-work .gallery-work-inner {
    min-width: calc(1024px - 40px);
  }
}
.gallery .gallery-work-content {
  width: calc(100% - 100px);
  margin: auto;
  position: relative;
}
.gallery .gallery-work-content hr {
  height: 1px;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.25);
  border: none;
}
.gallery .gallery-description-item {
  margin-bottom: 15px;
}
.gallery .gallery-description-item h2, .gallery .gallery-description-item h3, .gallery .gallery-description-item h4, .gallery .gallery-description-item h5, .gallery .gallery-description-item h6 {
  margin-bottom: 10px;
}
.gallery .gallery-description-item p {
  margin: 0;
  line-height: 1.9rem;
  font-size: 1.3rem;
}
.gallery .gallery-description-item a {
  display: inline-block;
  margin-bottom: 0;
  text-decoration: none;
  position: relative;
}
.gallery .gallery-description-item a:after {
  content: "";
  width: 90%;
  height: 2px;
  position: absolute;
  opacity: 0.25;
  background-color: MidnightBlue;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.25s;
}
.gallery .gallery-description-item a:hover:after {
  width: 100%;
  opacity: 1;
}
.gallery .gallery-work-image-wrapper {
  margin-bottom: 10px;
}
.gallery .gallery-work-image-wrapper:last-child {
  margin-bottom: 0;
}
.gallery .gallery-work-image-wrapper .gallery-work-image {
  width: auto;
  max-width: 100%;
}
.gallery .gallery-work-image-wrapper .loading-image {
  width: 70px;
}
.gallery .gallery-video iframe {
  border: none;
}
.gallery .gallery-video iframe video {
  height: auto;
}
.gallery .gallery-arrow {
  position: absolute;
  width: 50px;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 40px;
  cursor: pointer;
  z-index: 9999;
}
.gallery .gallery-arrow > img {
  width: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scaleX(1);
}
.gallery .gallery-arrow.gallery-left > img {
  left: 50%;
  transform: translateX(-50%) scaleX(-1) translateY(-50%);
}
.gallery .gallery-arrow.gallery-right {
  right: 0;
  left: auto;
}
.gallery .gallery-brands {
  min-height: 180px;
  margin-top: 20px;
}
.gallery .gallery-brands h2 {
  font-size: 1.75rem;
}
.gallery .gallery-brands .gallery-brands-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gallery .gallery-brands .gallery-brands-images > div {
  flex: 1 1 25%;
}
.gallery .gallery-brands .gallery-brands-images img {
  width: 100px;
}

.contact .contact-form {
  margin-top: 40px;
}
.contact .contact-form label {
  font-family: "Josefin Sans", sans-serif;
}
.contact .contact-form input, .contact .contact-form textarea {
  margin-bottom: 20px;
  font-family: "Josefin Slab", serif;
  border: 0;
  background: none;
  border-bottom: 2px solid #000;
}
.contact .contact-form input:focus, .contact .contact-form textarea:focus {
  outline: none;
}
.contact .contact-form textarea {
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  min-width: calc(100% - 20px);
  height: 100px;
  max-height: 100px;
  min-height: 100px;
}
@media only screen and (min-width: 561px) {
  .contact .contact-form textarea {
    min-width: 400px;
    max-width: 400px;
    width: 400px;
  }
}
.contact .contact-form .input-label-wrapper {
  padding-left: 10px;
}
.contact .contact-form .contact-form-inner {
  padding-left: 0;
  padding-right: 0;
}
.contact .contact-form .contact-form-inner > h2 {
  margin-bottom: 20px;
}
.contact .contact-form .submit-wrapper {
  position: relative;
  width: 169px;
}
.contact .contact-form .submit-wrapper button.submit-btn {
  border: 0;
  background: none;
}
.contact .contact-form .submit-wrapper button.submit-btn:focus-visible {
  outline: 0;
}

