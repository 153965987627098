.gallery {
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: #f8f8f8;

    img {
        // width: 100px;
    }

    .gallery-thumbnails-wrapper {
        padding-top: 40px;
        position: relative;

        @include respond-to(screen-sm) {
            top: 50%;
            transform: translateY(-50%);
        }

        @include respond-to(screen-md) {
            max-width: 690px;
            // max-width: 500px;
        }

        &:after {
            transform: rotate(1deg) translateX(-50%) translatey(-45%);
        }
    }

    .gallery-thumbnails {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 19px;

        button,
        a {
            cursor: pointer;
        }
    }

    .gallery-thumbnails-thumb {
        // margin: 10px;
        margin-right: 20px;
        margin-bottom: 20px;

        img {
            // width: 100px;
            width: 100%;
        }
    }

    .gallery-work {
        // .gallery-work-visible {}

        @keyframes fadeIn {
          from {opacity: 0;}
          to {opacity: 1;}
        }

        &.lightboxed {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 99999999999;
            background: rgba(0,0,0,0.75);
            overflow: hidden;
            animation-name: fadeIn;
            animation-duration: 0.5s;
        }

        .close-wrapper {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 50px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .gallery-work-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 90%;
            height: 100%;
            transform: translateX(-50%) translateY(-50%);
            padding: 50px 10px 20px;
            background-color: #fff;
            overflow-y: scroll;
            box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 50%);

            @include respond-to(screen-sm) {
                min-width: 500px;
                min-height: 556px;
                height: auto;
                max-height: 80%;
            }

            @media only screen and (max-height: 610px) {
                height: 80%;
                min-height: 80%;
            }

            @include respond-to(screen-md) {
                min-width: 768px;
                width: auto;
            }

            @include respond-to(screen-lg) {
                min-width: calc(1024px - 40px);
            }
        }
    }

    .gallery-work-content {
        width: calc(100% - 100px);
        margin: auto;
        position: relative;

        hr {
            height: 1px;
            margin-bottom: 15px;
            background: rgba(0, 0, 0, 0.25);
            border: none;
        }
    }

    .gallery-description-item {
        margin-bottom:15px;

        h2, h3, h4, h5, h6 {
            margin-bottom: 10px;
        }

        p {
            margin: 0;
            line-height: 1.9rem;
            font-size: 1.3rem;
        }

        a {
            display: inline-block;
            margin-bottom: 0;
            text-decoration: none;
            position: relative;

            &:after {
                content: "";
                width: 90%;
                height: 2px;
                position: absolute;
                opacity: 0.25;
                background-color: MidnightBlue;
                // bottom: 0;
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
                transition: $nav-animation-spd;
            }

            &:hover:after {
                width: 100%;
                opacity: 1;
            }
        }
    }

    .gallery-work-image-wrapper {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .gallery-work-image {
            width: auto;
            max-width: 100%;
            // width: 100px;
        }

        .loading-image {
            width: 70px;
        }
    }

    .gallery-work-thumb {
        .gallery-work-thumb-img {
            // width: 100px;
            // width: 100%;
        }
    }

    .gallery-video {
        iframe {
            border: none;

            video {
                height: auto;
            }
        }
    }

    .gallery-arrow {
            position: absolute;
            width: 50px;
            height: 100%;
            left: 0;
            top: 0;
            font-size: 40px;
            cursor: pointer;
            z-index: 9999;

            & > img {
                width: 30px;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) scaleX(1);
            }

        &.gallery-left > img {
            left: 50%;
            transform: translateX(-50%) scaleX(-1) translateY(-50%);
        }

        &.gallery-right {
            right: 0;
            left: auto;
        }
    }

    .gallery-brands {
        min-height: 180px;
        margin-top: 20px;

        h2 {
            font-size: 1.75rem;
        }

        .gallery-brands-images {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            & > div {
                flex: 1 1 25%;
            }

            img {
                width: 100px;
            }
        }
    }
}